import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {MatIconRegistry} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';


import {AuthService} from './auth/auth.service';
import * as schema from './schema/equipment.json';
import {CommonService} from "./shared/common/common-services/common-service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private userSubscription: Subscription;
  public user: any;

  constructor(
    private authService: AuthService,
    private cm: CommonService,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
    this.registerSvgIcons();
  }

  public ngOnInit() {

    // init this.user on startup
    this.authService.me().subscribe(data => {
      this.user = data.user;
    });

    // update this.user after login/register/logout
    this.userSubscription = this.authService.$userSource.subscribe((user) => {
      this.user = user;
    });
  }

  logout(): void {
    this.authService.signOut();
    this.navigate('');
  }

  navigate(link): void {
    this.router.navigate([link]);
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  registerSvgIcons() {
    [
      'close',
      'add',
      'add-blue',
      'airplane-front-view',
      'air-station',
      'balloon',
      'boat',
      'cargo-ship',
      'car',
      'catamaran',
      'clone',
      'convertible',
      'delete',
      'drone',
      'fighter-plane',
      'fire-truck',
      'horseback-riding',
      'motorcycle',
      'railcar',
      'railroad-train',
      'rocket-boot',
      'sailing-boat',
      'segway',
      'shuttle',
      'space-shuttle',
      'steam-engine',
      'suv',
      'tour-bus',
      'tow-truck',
      'transportation',
      'trolleybus',
      'water-transportation',
    ].forEach((icon) => {
      this.matIconRegistry.addSvgIcon(icon, this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon}.svg`));
    });
  }

  test() {
    // this.cm.getParseDataById('Address', 'peTxvZhB84').then(address => {
    //   address.inactive = true;
    //   address.save().then(res => {
    //   });
    // });
    this.cm.cloudFunction('stripeConnectionToken', {}).then(res => {
      debugger
    })
    // this.cm.cloudFunction('requestBatteryWithoutPhone', {
    //   id: 'pm_1KtLAW2eZvKYlo2Cj9FHINJI', fingerprint: 'pXl4o7b8eV9wH0ku', bcode: 'FYTADNAN9000TOUCH'
    //   id: 'pm_1KtLVd2eZvKYlo2CFCkCNbqa', fingerprint: '7n8VO9WvOJt2Uqeo', bcode: 'FYTADNAN9000TOUCH'
    // }).then(res => {
    //   debugger
    // });
  }
}
