import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatStepper} from '@angular/material';
import {Base} from "./base";

@Component({
  selector: 'ft-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css']
})
export class StepperComponent extends Base implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }
}
