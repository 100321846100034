import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FieldConfig} from './field-config';
import {debug} from 'util';

@Component({
  selector: 'ft-input-control',
  templateUrl: './input-control.component.html',
  styleUrls: ['./input-control.component.css']
})
export class InputControlComponent implements OnInit {
  @Input() form;
  _prop: FieldConfig;
  @Input()
  set prop(prop) {
    this._prop = prop;
  }

  get prop() {
    return this._prop;
  }

  constructor() {
  }

  ngOnInit() {
  }
}
