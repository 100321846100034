import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppMaterial} from '../app-material';
import {SaveButtonComponent} from './common-component/save-button-component';
import {CommonService} from './common-services/common-service';
import {EscapeHtmlPipe} from './common-pipes/escape-html-pipe';
import {DataTableComponent} from './common-component/data-table/data-table.component';
import {StepperComponent} from './common-component/stepper/stepper.component';
import {StepperButtonsComponent} from './common-component/stepper/stepper-buttons/stepper-buttons.component';
import {TabsComponent} from './common-component/tabs/tabs.component';
import {FooterComponent} from "./common-component/footer/footer.component";
import {NavbarComponent} from "./common-component/navbar/navbar.component";
import {SidebarComponent} from "./common-component/sidebar/sidebar.component";
import { RouterModule } from '@angular/router';
import {AuthModule} from "../../auth/auth.module";
import {AuthService} from "../../auth/auth.service";
import {ConfirmationComponent} from "./common-component/confirmation/confirmation.component";
import {InputControlModule} from "../input-control/input-control-module";

const COMPONENTS = [SaveButtonComponent, EscapeHtmlPipe, DataTableComponent,
  StepperComponent, StepperButtonsComponent, TabsComponent, FooterComponent, ConfirmationComponent,
  NavbarComponent,
  SidebarComponent];
@NgModule({
  declarations: [COMPONENTS],
  imports: [
    CommonModule,
    AppMaterial,
    RouterModule
  ],
  providers: [CommonService, AuthService],
  exports: [COMPONENTS]
})
export class CommonsModule { }
