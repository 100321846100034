import {Injectable} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {CommonService} from "../shared/common/common-services/common-service";

declare const Parse: any;


@Injectable()
export class ChargingBoxService {
  ChargingBoxStatus = 'ChargingBoxStatus';
  public $chargingBoxSub = new Subject<any>();

  getchargingBoxSub(): Observable<any> {
    return this.$chargingBoxSub.asObservable();
  }

  constructor(private http: HttpClient, private cm: CommonService) {
    // Parse.initialize(environment.PARSE_APP_ID); // , environment.PARSE_JS_KEY
    // Parse.serverURL = 'wss://getchargefuze.com';
    let query = new Parse.Query(this.ChargingBoxStatus);
    setTimeout(() => {
    let subscription = this.cm.getLiveQueryClient.subscribe(query);
    subscription.on('open', () => {
      // this.cm.showNotification('success', 'Live Query Functional');
    });
    // subscriptions
    // const query = new this.cm.ParseJs.Query(this.ChargingBoxStatus);
    // query.subscribe().then(subscriptionChargingBox => {
    //   subscriptionChargingBox.on('open', () => {
    //     alert('subscription opened');
    //   });
    subscription.on('create', (object) => {
      this.$chargingBoxSub.next('create');
      // alert('subscription create');
    });
    subscription.on('update', (object) => {
      this.$chargingBoxSub.next('update');
      // alert('subscription update');
    });
    subscription.on('delete', (object) => {
        this.$chargingBoxSub.next('delete');
      // alert('subscription deleted');
    });
    subscription.on('enter', (object) => {
        this.$chargingBoxSub.next('enter');
      // alert('subscription enter');
    });
    subscription.on('leave', (object) => {
        this.$chargingBoxSub.next('leave');
      // alert('subscription leave');
    });
    }, 4000);
  }
  getParse(objectName, pointers) {
    const Product = this.cm.ParseJs.Object.extend(objectName);
    const query = new this.cm.ParseJs.Query(Product);
    query.limit(1000);
    query.descending('createdAt');
    pointers.map(pointer => {
      query.include(pointer['relationName']);
    });
    return query.find();
  }

  getParseFindChargingBoxStatus(objectName, fieldName, fieldValue, pointers) {
    const Product = this.cm.ParseJs.Object.extend(objectName);
    const query = new this.cm.ParseJs.Query(Product);
    pointers.map(pointer => {
      // if (pointer['relation'])
      // query.include(pointer['relationName']);
      query.include(pointer['relationName']);
    });
    query.equalTo(fieldName, fieldValue);
    return query.find();
  }

  getParseDataChargingBoxStatus(results, pointers) {
    const arr = [];
    let temp: any = {};
    results.map((result, index) => {
      temp = {...temp, ...this.formatResultChargingBoxStatus(result, 'chargingBoxStatusId')};
      arr.push(temp);
      pointers.map(pointer => {
        if (result.attributes[pointer['relationName']]) {
          const object = {};
          object[pointer['relationName']] = this.formatResultChargingBoxStatus(result.attributes[pointer['relationName']], pointer['relationName'] + 'Id');
          arr[index] = {...arr[index], ...object};
          arr[index] = {...arr[index], ...object[pointer['relationName']]};
        }
      });
    });
    return arr;
  }

  formatResultChargingBoxStatus(result, id?) {
    let temp: any = {};
    temp = {...result.attributes};
    if (id)
      temp[id] = result.id;
    return temp;
  }

  getParseData(results, pointers) {
    const arr = [];
    let temp: any = {};
    results.map((result, index) => {
      temp = {...temp, ...this.formatResult(result, 'id')};
      temp['object'] = result;
      arr.push(temp);
      pointers.map(pointer => {
        if (result.attributes[pointer['relationName']]) {
          const object = {};
          object[pointer['relationName']] = this.formatResult(result.attributes[pointer['relationName']], 'id');
          arr[index] = {...arr[index], ...object};
          // arr[index] = {...arr[index], ...object[pointer['relationName']]};
          Object.keys(object[pointer['relationName']]).forEach(function(key) {
            if (key in arr[index]&& key !== 'type') { // or obj1.hasOwnProperty(key)
            } else { arr[index][key] = object[pointer['relationName']][key];}
          });
        }
      });
    });
    return arr;
  }

  formatResult(result, id?) {
    let temp: any = {};
    temp = {...result.attributes};
    temp['object'] = result;
    if (temp['location']) {
      temp['lat'] = temp['location']['_latitude'];
      temp['lng'] = temp['location']['_longitude'];
      temp['location'] = '(' + temp['location']['_latitude'] + ', ' + temp['location']['_longitude'] + ')';
    }
    if (id)
      temp[id] = result.id;
    return temp;
  }

  postParseData(data, parentForm, objectName, product) {
    const _ObjectParent = this.cm.ParseJs.Object.extend(objectName);
    const _objectParent = new _ObjectParent();
    const keys = Object.keys(data);
    parentForm.map(formObj => {
      _objectParent.set(formObj['key'], data[formObj['key']]);
    });
    _objectParent.set('product', product);
    _objectParent.save().then(prnt => {
      alert('added');
    }, (error) => {
      alert(error);
    });
  }

  getGeoPoint(lat, lng) {
    return new this.cm.ParseJs.GeoPoint(lat, lng);
  }

  getAddress(lat, lng) {
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=AIzaSyCgzZLKci027eBRixTxgy2Vb571nRJ4Vfw');
  }
}
