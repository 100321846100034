import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {CommonService} from "../shared/common/common-services/common-service";

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, AfterViewInit {
  @Input() filters: any = {};


  form: FormGroup;
  @Input()
  set resetFilter(reset) {
    this.form.reset();
  }
  @Output() applyFilter = new EventEmitter();
  filterColumn = {
    key: 'filterColumn',
    placeholder: 'Select Any Column',
    value: '',
    label: '',
    valueType: 'simple',//Object
    type: 'select',
    options: this.filters['columns'],
    change: (prop) => {
      if (prop) {
        console.log('filter')
        console.log(prop)
        this.addOptionToPointerColumn();
        this.typeOfColumn('filterColumn');
      }
    },
    viewValue: 'column',
    viewKey: 'column'
  };
  allOptions = [
    {
      opeationKey: 'exists',
      opeationValue: 'Exists'
    },
    {
      opeationKey: 'doesNotExist',
      opeationValue: 'Does Not Exists'
    },
    {
      opeationKey: 'EqualTo',
      opeationValue: 'Equals'
    },
    {
      opeationKey: 'notEqualTo',
      opeationValue: 'Does Not Equals'
    },
    {
      opeationKey: 'startsWith',
      opeationValue: 'Starts With'
    },
// {
//   opeationKey: 'endswith',
//   opeationValue: 'Ends With'
// },
    {
      opeationKey: 'fullText',
      opeationValue: 'String contain string'
    },
    {
      opeationKey: 'lessThan',
      opeationValue: 'lessThan'
    },
    {
      opeationKey: 'lessThanOrEqualTo',
      opeationValue: 'lessThanOrEqualTo'
    },
    {
      opeationKey: 'greaterThan',
      opeationValue: 'greaterThan'
    },
    {
      opeationKey: 'greaterThanOrEqualTo',
      opeationValue: 'greaterThanOrEqualTo'
    },
  ];
  textOptions = [
    {
      opeationKey: 'exists',
      opeationValue: 'Exists'
    },
    {
      opeationKey: 'doesNotExist',
      opeationValue: 'Does Not Exists'
    },
    {
      opeationKey: 'EqualTo',
      opeationValue: 'Equals'
    },
    {
      opeationKey: 'notEqualTo',
      opeationValue: 'Does Not Equals'
    },
    {
      opeationKey: 'startsWith',
      opeationValue: 'Starts With'
    },
    {
      opeationKey: 'fullText',
      opeationValue: 'String contain string'
    }
  ];
  numberOptions = [
    {
      opeationKey: 'exists',
      opeationValue: 'Exists'
    },
    {
      opeationKey: 'doesNotExist',
      opeationValue: 'Does Not Exists'
    },
    {
      opeationKey: 'EqualTo',
      opeationValue: 'Equals'
    },
    {
      opeationKey: 'notEqualTo',
      opeationValue: 'Does Not Equals'
    },
    {
      opeationKey: 'lessThan',
      opeationValue: 'lessThan'
    },
    {
      opeationKey: 'lessThanOrEqualTo',
      opeationValue: 'lessThanOrEqualTo'
    },
    {
      opeationKey: 'greaterThan',
      opeationValue: 'greaterThan'
    },
    {
      opeationKey: 'greaterThanOrEqualTo',
      opeationValue: 'greaterThanOrEqualTo'
    },
  ];
  pointerOptions = [
    {
      opeationKey: 'exists',
      opeationValue: 'Exists'
    },
    {
      opeationKey: 'doesNotExist',
      opeationValue: 'Does Not Exists'
    }
  ];

  pointerColumn = {
    key: 'pointerColumn',
    placeholder: 'Select Any Column',
    value: '',
    label: '',
    type: 'select',
    valueType: 'simple',//Object
    options: [],
    change: (prop) => {
      console.log('pointer')
      console.log(prop)
      if (prop) {
        this.typeOfColumn('pointerColumn');
      }
    },
    viewValue: 'column',
    viewKey: 'column'
  };
  filterOperation = {
    key: 'filterOperation',
    placeholder: 'select any operation',
    value: '',
    label: '',
    valueType: 'simple',//Object
    type: 'select',
    options: this.textOptions,
    change: (prop) => {
    },
    viewValue: 'opeationValue',
    viewKey: 'opeationKey'
  };
  textField = {
    key: 'filterField',
    placeholder: 'Enter Value',
    value: '',
    label: '',
    type: 'text'
  };
  numberField = {
    key: 'filterField',
    placeholder: 'Enter Value',
    value: '',
    label: '',
    type: 'number'
  };
  toggleField = {
    key: 'filterField',
    placeholder: 'Enter Value',
    value: '',
    label: '',
    type: 'toggle'
  };
  type = 'text';

  constructor(private _fb: FormBuilder, private cm: CommonService) {
    this.form = this._fb.group({});
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.filterColumn.options = this.filters['columns'];
  }

  get filterValues() {
    return this.form.value;
  }

  _filter() {
    // const fv: any = [];
    // this.filters.map(filter => {
    //   if (filter.pointer)
    //     fv.push({key: filter['pointer'] + '.' + filter['column'], value: this.filterValues[filter['column']]});
    //   else fv.push({key: filter['column'], value: this.filterValues[filter['column']]});
    // });
    if (this.isPointer >= 0) {
      this.applyFilter.emit({
        childObjectName: this.filters.ObjectNames[this.isPointer],
        childKey: this.filterValues['filterColumn'],
        fieldKey: this.filterValues['pointerColumn'],
        fieldValue: this.filterValues['filterField'],
        operation: this.filterValues['filterOperation']
      });
    } else {
      this.applyFilter.emit({
        fieldKey: this.filterValues['filterColumn'],
        fieldValue: this.filterValues['filterField'],
        operation: this.filterValues['filterOperation']
      });
    }
  }

  get isPointer() {
    return this.filters.pointers.indexOf(this.filterValues['filterColumn']);
  }

  get isExistOperation() {
    return this.filterValues['filterOperation'] === 'doesNotExist' || this.filterValues['filterOperation'] === 'exists';
  }

  addOptionToPointerColumn() {
    if (this.isPointer >= 0) {
      this.pointerColumn['options'] = this.filters[this.filterValues['filterColumn']];
    }
  }

  typeOfColumn(column) {
    if (column === 'filterColumn') {
      const _type = this.filters.columns.find(element => element.column === this.filterValues[column]);
      this.type = _type ? _type['type'] : 'text';
      debugger
    } else {
      debugger
      const _type = this.filters[this.filterValues['filterColumn']].find(element => element.column === this.filterValues[column]);
      this.type = _type ? _type['type'] : 'text';
    }
    // Changing operations options accordingly
    if (this.type === 'text') this.filterOperation['options'] = this.textOptions;
    else if (this.type === 'number') this.filterOperation['options'] = this.numberOptions;
    else if (this.type === 'pointer') this.filterOperation['options'] = this.pointerOptions;
    this.filterOperation = {...this.filterOperation};
  }
}
