import {} from 'googlemaps';
import {Component, EventEmitter, OnInit, Input, Output, AfterViewInit} from '@angular/core';
import {ElementRef, NgZone, ViewChild} from '@angular/core';
import {MapsAPILoader} from '@agm/core';
import {FieldConfig} from "../field-config";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-location-text-search',
  templateUrl: './location-text-search.component.html',
  styleUrls: ['./location-text-search.component.css']
})
export class LocationTextSearchComponent implements OnInit, AfterViewInit {
  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) {
  }

  _prop: FieldConfig;
  @Input() form;

  @Input()
  set prop(prop) {
    this._prop = prop;
    this.lat = parseFloat(prop.lat.toString());
    this.lng = parseFloat(prop.lng.toString());
  }

  get prop() {
    return this._prop;
  }

  lat = 34.0535063; // 31.5247;lahore
  lng = -118.46487589999998; // 74.3458;lahore
  // latC = 34.0535063; // 31.5247;lahore
  // lngC = -118.46487589999998; // 74.3458;lahore
  zoom = 10;

  ngOnInit() {
  }

  ngAfterViewInit() {
    // this.form.
    this.form.addControl('lat', new FormControl(this.lat));
    this.form.addControl('lng', new FormControl(this.lng));
    this.form.valueChanges.subscribe(data => {
      this.lat = parseFloat(data['lat']);
      this.lng = parseFloat(data['lng']);
    });
  }

  mapClicked($event) {
    this.lat = parseFloat($event.coords.lat);
    this.lng = parseFloat($event.coords.lng);
    this.form.patchValue({lat: this.lat, lng: this.lng});
    debugger
    // this.prop.change({lat: this.lat, lng: this.lng});
  }
}
