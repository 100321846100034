import {
  AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output,
  ViewChild
} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'ft-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, AfterViewInit, OnChanges {
  columnLabels = [];
  columnsToDisplay = [];
  tableDataSource: MatTableDataSource<any>;
  temptablesData: any = {};
  _count = 0;
  @Output() pageChange = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() detail = new EventEmitter();
  @Output() pointerDetailPage = new EventEmitter();

  @Input() filter = true;
  @Input() loading = false;

  @Input()
  set count(count) {
    this._count = count;
    // this.tableDataSource.paginator = this.paginator;
  }

  get count() {
    return this._count;
  }

  @Input()
  set tableData(tableData) {
    this.columnLabels = tableData.columnLabels;
    this.columnsToDisplay = tableData.columnsToDisplay;
    this.temptablesData = tableData;
    this.tableDataSource = new MatTableDataSource<any>();
  }

  get tableData() {
    return this.temptablesData;
  }

  selection = new SelectionModel<any>(true, []);

  constructor(private cd: ChangeDetectorRef) {
  }

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  ngOnInit() {

  }

  ngAfterViewInit() {
    // this.tableDataSource.paginator = this.paginator;
    this.tableDataSource.sort = this.sort;
    this.tableDataSource.data = <any>this.tableData.data;
  }

  ngOnChanges() {
    // this.tableDataSource.paginator = this.paginator;
    this.tableDataSource.sort = this.sort;
    this.tableDataSource.data = <any>this.tableData.data;
  }

  _pageChange(page) {
    this.pageChange.emit({pageNumber: page.pageIndex + 1, pageSize: page.pageSize});
  }

  sortData(event) {
    debugger;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.tableDataSource.data.forEach(row => this.selection.select(row));
    this.cd.detectChanges();
    debugger
  }

  applyFilter(filterValue: string) {
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
  }

  _edit(row) {
    debugger
    this.edit.emit(row);
  }

  _delete(row) {
    this.delete.emit(row);
  }

  detailPage(row) {
    if (this.tableData.detailPage)// detail page for whole row with back button
      this.pointerDetailPage.emit({row: row, detailKey: 'detailPageForm', detailPage: true});
    else this.detail.emit(row);
  }

  _pointerDetailPage(row, object) {
    this.pointerDetailPage.emit({row: row, detailKey: object['detailKey']});
  }

  pointerCell(column) {
    if (typeof column === 'string') return false;
    return true;
  }

  handleUndefinedPointerValues(element, ind) {
    if (element[this.columnLabels[ind]['detailKey']] && element[this.columnLabels[ind]['detailKey']][this.columnLabels[ind]['column']]) {
      return element[this.columnLabels[ind]['detailKey']][this.columnLabels[ind]['column']];
    } else {
      return 'Invalid Pointer';
    }
  }
}
