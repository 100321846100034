import { OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { EventEmitter, Output } from "@angular/core";
import { OverlayService } from './overlay.service';

export class OverlayRefMain {

  constructor(private overlayRef: OverlayRef, private overlayService: OverlayService) { }

  close(): void {
    //this.overlayRef.dispose();
    this.overlayService.closeOverlay();
  }

  updateSize(height, width) {
    this.overlayRef.updateSize({ height: height, width: width });
    this.overlayRef.updatePosition();
  }

  getConfig(): OverlayConfig {
    return this.overlayRef.getConfig();
  }
}
