import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FieldConfig, maskedInput} from '../field-config';
import {regexValidator} from '../custom-validators';

@Component({
  selector: 'ft-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit {
  showScan = false;
  @Input() form;
  _prop: FieldConfig;
  @Input()
  set prop(prop) {
    this._prop = prop;
    if (prop) {
      this.showScan = prop['qrcodeShow'];
      this.addControl(prop);
      this.addValidators(prop);
    }
  }

  get prop() {
    return this._prop;
  }

  constructor() {
  }

  ngOnInit() {
  }

  addControl(prop) {
    this.form.addControl(prop.key, new FormControl(prop.value));
  }

  addValidators(prop) {
    if (prop.validation) {
      const keys = Object.keys(prop.validation);
      const validators = [];
      for (const key of keys) {
        if (key === 'required') {
          validators.push(Validators.required);
        } else if (key === 'min') {
          validators.push(Validators.min(prop.validation[key]));
        } else if (key === 'max') {
          validators.push(Validators.max(prop.validation[key]));
        } else if (key === 'minLength') {
          validators.push(Validators.minLength(prop.validation[key]));
        } else if (key === 'maxLength') {
          validators.push(Validators.maxLength(prop.validation[key]));
        } else if (key === 'regex') {
          validators.push(regexValidator(prop.validation[key]));

        } else if (key === '') {

        }
      }
      this.form.controls[prop.key].setValidators(validators);
      this.form.controls[prop.key].updateValueAndValidity();
    }
  }

  getErrorMessage() {
    if (this.form.controls[this.prop.key].touched) {
      if (this.form.controls[this.prop.key].hasError('required')) {
        return this.prop.errorMessage['required'];
      } else if (this.form.controls[this.prop.key].hasError('regex')) {
        return this.prop.errorMessage['regex'];
      } else if (this.form.controls[this.prop.key].hasError('max')) {
        return this.prop.errorMessage['max'];
      } else if (this.form.controls[this.prop.key].hasError('min')) {
        return this.prop.errorMessage['min'];
      } else if (this.form.controls[this.prop.key].hasError('maxlength')) {
        return this.prop.errorMessage['maxLength'];
      } else if (this.form.controls[this.prop.key].hasError('minlength')) {
        return this.prop.errorMessage['minLength'];
      } else {
        return '';
      }
    }

  }

  getMask(maskInput) {
    if (maskInput === maskedInput.ssn) {
      return ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    } else if (maskInput === maskedInput.phone) {
      return [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }
  }

  scanSuccess(code) {
    // code = 'getchargefuze.com/fuzebox?box=4567'
    if (this.prop.key === 'bcode') {
      let pair = code.split('=');
      const bcode = pair[1];
      // pair = pair[0].split('/');
      // pair = pair[1].split('?');
      // const name = pair[0]
      this.prop.change(bcode);
      const object = {};
      object[this.prop.key] = bcode;
      object['deviceName'] = bcode;
      this.form.patchValue(object);
      this.showScan = false;
    } else {
      const object = {};
      object[this.prop.key] = code;
      this.form.patchValue(object);
      this.showScan = false;
    }
  }

  getLocation(href) {
    const l = document.createElement("a");
    l.href = href;
    return l;
  }
}
