import {Routes} from '@angular/router';
import {ChargingBoxComponent} from '../../../charging-box/charging-box.component';
import {AuthService} from '../../../auth/auth.service';
import {AuthGuard} from '../../../auth/auth-guard.service';
import {ChargingBoxDetailComponent} from '../../../charging-box/charging-box-detail/charging-box-detail.component';
export const AdminLayoutRoutes: Routes = [
  {path: 'chargingbox-detail', component: ChargingBoxDetailComponent, canActivate: [AuthGuard]},
  {path: '', component: ChargingBoxComponent, canActivate: [AuthGuard]},
  {path: 'charging-box', component: ChargingBoxComponent, canActivate: [AuthGuard]},
];
