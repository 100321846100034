import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StepperComponent} from '../stepper.component';

@Component({
  selector: 'ft-stepper-buttons',
  templateUrl: './stepper-buttons.component.html',
  styleUrls: ['./stepper-buttons.component.css']
})
export class StepperButtonsComponent implements OnInit {

  constructor() {
  }
  @Input()isShowNext = true;
  @Input()isShowPrev = true;
  @Output() onNextClick = new EventEmitter<any>();
  @Output() onPreviousClick = new EventEmitter<any>();
  ngOnInit() {
  }
}
