import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ft-save-button',
  template: `<button mat-raised-button (click)="SaveButtonClick.emit()" color="primary">{{saveButtonText}}</button>`,
})
export class SaveButtonComponent {
  @Input()saveButtonText = 'Save';
  @Output()SaveButtonClick = new EventEmitter();
}
