import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  @Input() cancelbtn = 'Cancel';
  @Input() confirmBtn = 'Ok';
  @Output() cancel = new EventEmitter();
  @Output() confirm = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  _confirm() {
    this.confirm.emit();
  }

  _cancel() {
    this.cancel.emit();
  }
}
