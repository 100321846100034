import {
  Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef, ViewChild, AfterViewInit, OnChanges,
  AfterContentInit,
  ChangeDetectionStrategy,
  InjectionToken
} from '@angular/core';
import {Subscription, BehaviorSubject, Observable, Subject} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {OverlayRefMain} from "../../overlay/overlay-ref";

type BtnCommand = 'save' | 'next';
type BtnState = 'save' | 'next';
export const CRUD = new InjectionToken<Subject<any>>('CRUD');

@Component({
  selector: 'ms-crud-form',
  templateUrl: './crud-form.component.html',
  styleUrls: ['./crud-form.component.scss'],
  providers: [{provide: CRUD, useFactory: () => new Subject()}],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrudFormComponent implements OnInit, AfterViewInit {
  @Input()
  set close(close) {
    if (close) this.closeDiaLog();
  };

  @Input() confirmBtnText = 'Save';
  @Input() cancelBtnText = 'Cancel';
  @Input() apiCall;
  @Input() showSaveButton = true;
  @Input() form;
  @Input() disableSave;
  @Input() customClass: string = ' ';
  @Input() title;
  @Input() data;
  @Input() overlay: OverlayRefMain;
  @Output() saved = new EventEmitter();
  @Output() canceled = new EventEmitter();
  @Input() headerIcons =
    {
      expand: true,
      minimize: true,
      close: true,

    };
  isMobileScreen = false;
  defaultHeight = '88%';
  defaultWidth = '52.61%';
  // @ViewChild(SaveButtonDirective) saveBtn: SaveButtonDirective;
  private _btnDisabled$ = new BehaviorSubject<boolean>(false);

  @Input()
  set btnDisabled(disabled: boolean) {
    this._btnDisabled$.next(disabled);
    this.cdRef.markForCheck();
  }

  get btnDisabled(): boolean {
    return this._btnDisabled$.getValue();
  }

  get btnDisabled$(): Observable<boolean> {
    return this._btnDisabled$.asObservable();
  }

  private _btnCommand$ = new Subject<BtnCommand>();

  @Input()
  btnCommand(command: BtnCommand) {
    this._btnCommand$.next(command ? command : this.btnState);
  }

  get btnCommand$(): Observable<BtnCommand> {
    return this._btnCommand$.asObservable();
  }

  private _btnState$ = new BehaviorSubject<BtnState>(null);

  @Input()
  set btnState(state: BtnState) {
    this._btnState$.next(state);
  }

  get btnState() {
    return this._btnState$.getValue();
  }

  get btnState$(): Observable<any> {
    return this._btnState$.asObservable();
  }

  constructor(private cdRef: ChangeDetectorRef, breakpointObserver: BreakpointObserver, private cd: ChangeDetectorRef) {
    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.expandDialogOnly();
        this.cd.detectChanges();
      }
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  get isFullScreen() {
    const overlayConfig = this.overlay.getConfig();
    return overlayConfig.width === '100%';
  }

  closeDiaLog() {
    this.canceled.emit(true);
    this.overlay.close();
  }

  save() {
    this.saved.emit();
  }

  gg() {
    // if (this.saveBtn) console.log('gg', this.saveBtn.callingApi);
  }

  expandDialogOnly() {
    this.updateSize('100%', '100%');
    console.log('expandDialogOnly', this.overlay, this.overlay.getConfig(), this.isFullScreen);
  }

  expandDialog() {
    let width = this.defaultWidth;
    let height = this.defaultHeight;
    if (!this.isFullScreen) {
      width = '100%';
      height = '100%';
    }
    console.log('expandDialog', this.overlay, height, width);
    this.updateSize(height, width);
  }

  updateSize(height, width) {
    this.overlay.updateSize(height, width);
  }
}
