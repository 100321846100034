import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
