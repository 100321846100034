import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FieldConfig} from '../field-config';
import {CommonService} from '../../common/common-services/common-service';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss']
})
export class AutoCompleteComponent implements AfterViewInit {
  options: any = [];
  optionsShow = false;
  @Input() form: FormGroup;
  _prop: FieldConfig;
  @Input()
  set prop(prop) {
    this._prop = prop;
    if (prop) {
      this.addControl(prop);
      this.addValidators(prop);
      this.addOptions(prop);
    }
  }

  get prop() {
    return this._prop;
  }

  constructor(private cm: CommonService) {
  }

  myControl = new FormControl();
  filteredOptions: Observable<string[]>;

  ngAfterViewInit() {
    this.filteredOptions = this.form.get(this.prop.key).valueChanges.pipe(
      startWith(''),
      map(value => value ? this._filter(value) : this.prop.options.slice())
    );
    // .subscribe(value => value ? this._filter(value) : this.prop.options.slice());
  }

  private _filter(value: string): any[] {
    const filterValue = value;

    return this.prop.options.filter(option => option[this.prop.viewValue].toLowerCase().includes(filterValue));
    // this.filteredOptions = of(this.prop.options.filter(option => option[this.prop.viewValue].toLowerCase().includes(filterValue)));
  }


  addOptions(prop) {
    if (prop['isOptionsPromise']) {
      prop['options'].then(results => {
        debugger
        prop['options'] = this.cm.getOptionsData(results, 'id');
        // this.filteredOptions = of(this.cm.getOptionsData(results, 'id'));
        this.optionsShow = true;
        prop['isOptionsPromise'] = false;
      })
        .catch(function (error) {
          console.log(error);
          this.cm.showNotification('danger', error);
          // There was an error.
        });
    } else {
      this.optionsShow = true;
    }
  }

  addControl(prop) {
    this.form.addControl(prop.key, new FormControl(prop.value));
  }

  addValidators(prop) {
    if (prop.validation) {
      const keys = Object.keys(prop.validation);
      const validators = [];
      for (const key of keys) {
        if (key === 'required') {
          validators.push(Validators.required);
        } else if (key === '') {

        }
      }
      this.form.controls[prop.key].setValidators(validators);
    }
  }

  displayProperty = value => {
    if (value) {
      return value[this.prop.viewValue];
    }
  }
}
