import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';

import {TokenStorage} from './token.storage';
import {TooltipComponent} from '@angular/material';
import {environment} from "../../environments/environment";
import {Router} from "@angular/router";
import {CommonService} from "../shared/common/common-services/common-service";

// declare const Parse: any;

@Injectable()
export class AuthService {

  constructor(private http: HttpClient, private token: TokenStorage, private router: Router, private cm: CommonService) {
    // this.cm.ParseJs.initialize(environment.PARSE_APP_ID);//, environment.PARSE_JS_KEY
    // this.cm.ParseJs.serverURL = environment.serverURL;
  }

  public $userSource = new Subject<any>();

  parseLogin(username, password) {
    this.cm.ParseJs.User.logIn(username, password).then(user => {
      this.token.saveToken(user['attributes']['username']);
      this.router.navigate(['/charging-box']);
    }, error => {
      this.cm.showNotification('danger', error);
    });
  }

  parseSignup() {
    let user = new this.cm.ParseJs.User();
    user.set("username", "danishumer");
    user.set("password", "Danishumer123456");
    user.set("email", "email@example.com");
    user.signUp().then(success => {
      alert(success);
    }, error=>{
      alert(error);
    });
  }

  login(email: string, password: string): Observable<any> {
    return Observable.create(observer => {
      this.http.post('/api/auth/login', {
        email,
        password
      }).subscribe((data: any) => {
        observer.next({user: data.user});
        this.setUser(data.user);
        this.token.saveToken(data.token);
        observer.complete();
      })
    });
  }

  register(fullname: string, email: string, password: string, repeatPassword: string): Observable<any> {
    return Observable.create(observer => {
      this.http.post('/api/auth/register', {
        fullname,
        email,
        password,
        repeatPassword
      }).subscribe((data: any) => {
        observer.next({user: data.user});
        this.setUser(data.user);
        this.token.saveToken(data.token);
        observer.complete();
      })
    });
  }

  setUser(user): void {
    if (user) user.isAdmin = (user.roles.indexOf('admin') > -1);
    this.$userSource.next(user);
    (<any>window).user = user;
  }

  getUser(): Observable<any> {
    return this.$userSource.asObservable();
  }

  me(): Observable<any> {
    return Observable.create(observer => {
      const tokenVal = this.token.getToken();
      if (!tokenVal) return observer.complete();
      this.http.get('/api/auth/me').subscribe((data: any) => {
        observer.next({user: data.user});
        this.setUser(data.user);
        observer.complete();
      })
    });
  }

  signOut(): void {
    this.token.signOut();
    this.setUser(null);
    delete (<any>window).user;
  }
}
