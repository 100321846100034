export interface FieldConfig {
  key: string;
  placeholder: string;
  value: string;
  label: string;
  hint: string;
  disabled: boolean;
  qrcode: boolean;
  formInline?: boolean;
  cssClass?: string;
  errorMessage?: string;
  isOptionsPromise: boolean;
  options?: any;
  lat: number;
  lng: number;
  viewValue?: string;
  viewKey?: string;
  selected: boolean; // default select first value in drop down
  template?: boolean; // for showing battery info
  form?: any; // for pointer link Parse
  change: Function;
  validation?: {
    required?: boolean,
    minLength?: number,
    maxLength?: number,
    length?: number,
    min?: number,
    max?: number,
    regex?: RegExp
  };
  type: string;
  maskedInput?: string;
}

export enum maskedInput {
  ssn = 'SSN',
  phone = 'PHONE',
  date = 'DATE'
}
