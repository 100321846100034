import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './auth/auth-guard.service';
import {AdminLayoutComponent} from './shared/layouts/admin-layout/admin-layout.component';

const routes: Routes = [
//   {
//   path: '',
//   component: HomeComponent
// },
  {
    path: '',
    component: AdminLayoutComponent,
    // canActivate: [AuthGuard],
    children: [{
      path: '',
      loadChildren: './shared/layouts/admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  },
  {
    path: 'auth',
    loadChildren: 'app/auth/auth.module#AuthModule'
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
  declarations: []
})

export class AppRoutingModule {
}
