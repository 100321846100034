import { Component, OnInit } from '@angular/core';
export enum alignTabs {
  start= 'start',
  center= 'center',
  end= 'end'
}
@Component({
  selector: 'ft-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})

export class TabsComponent implements OnInit {
  dynamicHeight = false;
  alignTabs = alignTabs.start;
  animationDuration = '100ms';
  backgroundColor = 'primary';
  constructor() { }

  ngOnInit() {
  }
  selectedTabChange(event) {
    debugger
  }
  focusChange(event) {
    debugger
  }
  selectedIndexChange(event) {
    debugger
  }
}
