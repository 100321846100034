import {EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatStepper} from '@angular/material';
export class Base {
  @Input()isLinear = true;
  @Input()labelPosition = 'bottom';
  @ViewChild('stepper',{static: false}) stepper: MatStepper;
  @Output() onPreviousEvent = new EventEmitter<any>();
  @Output() onNextEvent = new EventEmitter<any>();
  next(event) {
    if (this.stepper !== undefined && this.stepper.selectedIndex < this.stepper._steps.length - 1) {
      this.stepper.next();
    }
    else {
      this.onNextEvent.emit();
    }

    debugger
  }
  prev(event) {
    if (this.stepper && this.stepper.selectedIndex > 0) {
      this.stepper.previous();
    }
    else {
      this.onPreviousEvent.emit();
    }
    debugger
  }
  get isFirstStep() {
    if (this.stepper !== undefined && this.stepper._steps) {
      if (this.stepper.selectedIndex === 0) {
        return true;
      } else { return false; }
    }
  }
  get isLastStep() {
    if (this.stepper !== undefined && this.stepper._steps) {
      if (this.stepper.selectedIndex === this.stepper._steps.length - 1) {
        return true;
      } else { return false; }
    }
  }
}
