import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {AuthService} from '../auth.service';
import {CommonService} from "../../shared/common/common-services/common-service";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../auth.component.scss']
})
export class LoginComponent implements OnInit {
  data = [
    {
      key: 'email',
      placeholder: 'email',
      value: '',
      label: 'email:',
      type: 'email',
    },
    {
      key: 'password',
      placeholder: 'password',
      value: '',
      label: 'password:',
      type: 'password',
    }
  ]
  form: FormGroup;

  get email() {
    return this.form.value['email'];
  }

  get password() {
    return this.form.value['password'];
  }

  constructor(private _fb: FormBuilder, private commonService: CommonService, private authService: AuthService, private router: Router) {
    this.form = this._fb.group({});
  }

  ngOnInit() {
    this.authService.signOut();
  }

  login(): void {
    debugger
    this.authService.parseLogin(this.email, this.password);
    // this.authService.parseSignup();
  }
  sihnup(): void {
    debugger
    this.authService.parseSignup();
    // this.authService.parseSignup();
  }

}
