import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';

import { AppComponent } from './app.component';
import {AdminLayoutComponent} from './shared/layouts/admin-layout/admin-layout.component';
import {AdminLayoutModule} from './shared/layouts/admin-layout/admin-layout.module';
import {CommonsModule} from './shared/common/common-module';
import {AgmCoreModule} from '@agm/core';
import {AppRoutingModule} from './app-routing.module';

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
  ],
  imports: [
    BrowserModule,
    CommonsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
    AuthModule,
    AppRoutingModule,
    AdminLayoutModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCgzZLKci027eBRixTxgy2Vb571nRJ4Vfw',
      libraries: ['places']
    })
  ],
  providers: [],
  entryComponents: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
