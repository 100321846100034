import { Component, Input, Inject, HostListener } from '@angular/core';

import { OverlayRefMain } from './overlay-ref';
import { DIALOG_COMPONENT, DIALOG_DATA } from './overlay.tokens';
import { PortalHost } from '@angular/cdk/portal';
export const ESCAPE = 27;
@Component({
  selector: 'ms-overlay',
  template: `
    <div class="overlay-content">
    <ng-template *ngIf="selectedPortal" [cdkPortalOutlet]="selectedPortal"></ng-template>
    </div>
  `,
  styles: [`
    /* :host {
      display: flex;
      flex-direction: column;
      align-items: center;
    } */

    h1 {
      margin: 0;
      padding: 1em;
    }

    img {
      width: 100%;
      max-width: 500px;
      height: auto;
    }

    .overlay-content {
      background: #fff;
      pointer-events: auto;
      width: 100%;
      height: 100%;
    }
  `]
})
export class OverlayComponent {
  @HostListener('document:keydown', ['$event']) private handleKeydown(event: KeyboardEvent) {
    if (event.keyCode === ESCAPE) {
      this.dialogRef.close();
    }
  }
  constructor(
    public dialogRef: OverlayRefMain,
    @Inject(DIALOG_COMPONENT) public selectedPortal: PortalHost,
    @Inject(DIALOG_DATA) public data: any) { console.log(selectedPortal, dialogRef, data); }
}
