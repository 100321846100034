import {NgModule} from '@angular/core';
import {
  MatCommonModule, MatFormFieldModule, MatInputModule, MatNativeDateModule, MatOptionModule, MatPaginatorModule,
  MatSortModule
} from '@angular/material';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {LayoutModule} from '@angular/cdk/layout';
import {ScrollDispatchModule} from '@angular/cdk/scrolling';
import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {PortalModule} from '@angular/cdk/portal';
import {OverlayComponent} from './common/overlay/overlay.component';
import {CrudFormComponent} from './common/common-component/crud-form/crud-form.component';
import {OverlayService} from './common/overlay/overlay.service';
import {MatRadioModule} from '@angular/material/radio';
import {MatIconModule} from '@angular/material';
// import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AgmCoreModule} from '@agm/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { TextMaskModule } from 'angular2-text-mask';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from '@angular/material/tabs';
import {MatStepperModule} from '@angular/material/stepper';
import {MatCardModule} from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {PlatformModule} from "@angular/cdk/platform";
const MODULES = [
  MatFormFieldModule,
  ZXingScannerModule,
  MatProgressSpinnerModule,
  MatInputModule,
  AgmCoreModule,
  PortalModule,
  CommonModule,
  OverlayModule,
  ScrollDispatchModule,
  MatOptionModule,
  MatCommonModule,
  LayoutModule,
  // NgbModule,
  MatIconModule,
  MatAutocompleteModule,
  MatRadioModule,
  TextMaskModule,
  MatSelectModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSlideToggleModule,
  MatProgressBarModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatStepperModule,
  MatTabsModule,
  PlatformModule,
  MatButtonModule,
  MatCardModule
];

const COMPONENTS = [
  CrudFormComponent
  ];
@NgModule({
  imports: [
    ...MODULES
  ],
  declarations: [
    ...COMPONENTS,
    OverlayComponent
  ],
  entryComponents: [
    OverlayComponent
  ],
  providers: [
    OverlayService
  ],
  exports: [
    ...COMPONENTS,
    ...MODULES
  ]
})
export class AppMaterial {
}
