import {Injectable} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {environment} from "../../environments/environment";
import {CommonService} from "../shared/common/common-services/common-service";

// declare const Parse: any;


@Injectable()
export class EjectBatteryService {
  public $pointerData = new Subject<any>();

  getPointerData(): Observable<any> {
    return this.$pointerData.asObservable();
  }

  constructor(private cm: CommonService) {
    // this.cm.ParseJs.initialize(environment.PARSE_APP_ID);//, environment.PARSE_JS_KEY
    // this.cm.ParseJs.serverURL = environment.serverURL;
  }

  getParseById(objectName, id, pointers) {
    debugger
    const Product = this.cm.ParseJs.Object.extend(objectName);
    const query = new this.cm.ParseJs.Query(Product);
    pointers.map(pointer => {
      // if (pointer['relation'])
      // query.include(pointer['relationName']);
      query.include(pointer['relationName']);
    });
    return query.get(id);
  }

  getParse(objectName, pointers) {
    const Product = this.cm.ParseJs.Object.extend(objectName);
    const query = new this.cm.ParseJs.Query(Product);
    // query.include('Address');
    query.descending('createdAt');
    pointers.map(pointer => {
      // if (pointer['relation'])
      // query.include(pointer['relationName']);
      query.include(pointer['relationName']);
    });
    return query.find();
  }

  // getParseData(results, pointers) {
  //   const arr = [];
  //   let temp: any = {};
  //   results.map((result, index) => {
  //     temp = {...temp, ...this.formatResult(result)};
  //     arr.push(temp);
  //     pointers.map(async pointer => {
  //       const arrrelatedObject = [];
  //       if (pointer['relation']) {
  //         // result;
  //         // arr;
  //         await result.relation(pointer['relationName']).query().each((relatedObject) => {
  //           console.log(relatedObject);
  //           arrrelatedObject.push(this.formatResult(relatedObject));
  //         });
  //         let object = {}
  //         object[pointer['relationName']] = arrrelatedObject;
  //         arr[index] = {...arr[index], ...object};
  //         // temp = {...temp, ...object};
  //         // arr.push(temp);
  //         this.$pointerData.next(arr);
  //       }
  //       else if (result.attributes[pointer['relationName']]) {
  //         let object = {}
  //         object[pointer['relationName']] = this.formatResult(result.attributes[pointer['relationName']]);
  //         arr[index] = {...arr[index], ...object};
  //         // temp = {...temp, ...this.formatResult(result.attributes[pointer['relationName']])};
  //       }
  //     });
  //   });
  //   return arr;
  // }
  getParseData1(results, pointers) {// getting data from battries
    const arr = [];
    let temp: any = {};
    results.map((result, index) => {
      const tmpchr = this.formatResult(result, 'batteryId');
      temp = {...temp, ...tmpchr};
      arr.push(temp);
      pointers.map(pointer => {
        if (result.attributes[pointer['relationName']]) {
          const object = {battery: []};
          object[pointer['relationName']] = this.formatResult(result.attributes[pointer['relationName']], 'chargingBoxId');
          object['battery'].push(tmpchr);
          arr[index] = {...arr[index], ...object};
          arr[index] = {...arr[index], ...object[pointer['relationName']]};
          // temp = {...temp, ...this.formatResult(result.attributes[pointer['relationName']])};
        }
      });
    });
    return arr;
  }

  getParseData(results, pointers) { // copied from chargingboxservice
    const arr = [];
    const batteryPromise = [];
    results.map((result, index) => {
      let temp: any = {battery: []};
      temp = {...temp, ...this.formatResult(result, 'chargingBoxId')};
      batteryPromise.push(this.cm.getParseFind('Battery', 'chargingBox', result))
      // this.cm.getParseFind('Battery', 'chargingBox', result)
      arr.push(temp);
    });
    return [arr, batteryPromise];
  }

  getParseDataById(result, pointers) { // copied from chargingboxservice
    const batteryPromise = [];
    let temp: any = {battery: []};
    temp = {...temp, ...this.formatResult(result, 'chargingBoxId')};
    if (result.attributes['address'])
    temp['address'] = {...temp['address'], ...this.formatResult(result.attributes['address'], 'id')};
    batteryPromise.push(this.cm.getParseFind('Battery', 'chargingBox', result))
    // this.cm.getParseFind('Battery', 'chargingBox', result)
    return [temp, batteryPromise];
  }
  formatResult(result, id?) {
    let temp: any = {};
    temp = {...result.attributes};
    temp[id] = result.id;
    return temp;
  }

  ejectBattery(params) {
    this.cm.ParseJs.Cloud.run('ejectBattery', params).then(success => {
      this.cm.showNotification('success', success);
    }, error => {
      this.cm.showNotification('danger', error);
    });
  }
}
