import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FieldConfig} from "../field-config";

@Component({
  selector: 'ft-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss']
})
export class CheckboxInputComponent implements OnInit {
  @Input() form;
  _prop: FieldConfig;
  @Input()
  set prop(prop) {
    this._prop = prop;
    if (prop) {
      this.addControl(prop);
      this.addValidators(prop);
    }
  }

  get prop() {
    return this._prop;
  }

  constructor() {
  }

  ngOnInit() {
  }

  addControl(prop) {
    this.form.addControl(prop.key, new FormControl(prop.value));
  }

  addValidators(prop) {
    if (prop.validation) {
      const keys = Object.keys(prop.validation);
      const validators = [];
      for (const key of keys) {
        if (key === 'required') {
          validators.push(Validators.required);
        } else if (key === '') {

        }
      }
      this.form.controls[prop.key].setValidators(validators);
    }
  }

  getErrorMessage() {
    if (this.form.controls[this.prop.key].touched) {
      if (this.form.controls[this.prop.key].hasError('required')) {
        return this.prop.placeholder + ' can`t be null';
      }
      else return '';
    }

  }
}
