import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AdminLayoutRoutes} from './admin-layout.routing';
import {
  MatButtonModule,
  MatInputModule,
  MatRippleModule,
  MatFormFieldModule,
  MatTooltipModule,
  MatSelectModule
} from '@angular/material';
import {CommonsModule} from '../../common/common-module';
import {InputControlModule} from '../../input-control/input-control-module';

import {ChargingBoxModule} from '../../../charging-box/charging-box.module';
import {SharedModule} from '../../shared.module';
import {AuthModule} from '../../../auth/auth.module';
import {AppMaterial} from '../../app-material';
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AdminLayoutRoutes),
    CommonsModule,
    SharedModule,
    AuthModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterial,
    InputControlModule,
    ChargingBoxModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
  ],
  declarations: []
})

export class AdminLayoutModule {
}
