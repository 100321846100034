import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { environment } from "../../../../environments/environment";
declare const Parse: any;
declare const $: any;

@Injectable()
export class CommonService implements OnDestroy {
  private customerAttributes = {
    // btnText: '', // if add button show
    detailPage: true,
    columnLabels: [
      'select',
      'User Name',
      'Email Verified',
      'Language',
      'Email',
      // 'Customer First Name',
      // 'Customer Name Last',
      // 'Customer Number',
      // 'Customer Email',
      // 'App Download Date',
      // 'Has Payment Info',
      // 'Has Rented',
      // 'First Rental Date',
      // 'Last Rental Date',
      // 'Rentals Lifetime',
      // 'Rentals 7d',
      // 'Rentals 30d',
      // 'Lifetime Spend',
      // 'Push Enabled',
      // 'Is Renting',
      // 'Avg Rental Time'
    ],
    columnsToDisplay: [
      'select',
      'username',
      'emailVerified',
      'language',
      'email',
      // 'firstName',
      // 'lastName',
      // 'customer_number',
      // 'email',
      // 'app_download_date',
      // 'has_payment_info',
      // 'has_rented',
      // 'first_rental_date',
      // 'last_rental_date',
      // 'rentals_lifetime',
      // 'rentals_7d',
      // 'rentals_30d',
      // 'lifetime_spend',
      // 'push_enabled',
      // 'is_renting',
      // 'Avg_rental_time'
    ],
    data: []
  };
  public $pointerData = new Subject<any>();

  getPointerData(): Observable<any> {
    return this.$pointerData.asObservable();
  }

  private rentalAttributes = {
    filters: {
      columns: [
        {
          column: 'status',
          type: 'text'
        },
        {
          column: 'rentedDurationMinutes',
          type: 'number'
        },
        {
          column: 'summaryStatus',
          type: 'text'
        },
        {
          column: 'pickedFrom',
          type: 'pointer'
        },
        {
          column: 'returnTo',
          type: 'pointer'
        },
        {
          column: 'user',
          type: 'pointer'
        },
        {
          column: 'battery',
          type: 'pointer'
        },
        {
          column: 'invoice',
          type: 'pointer'
        }
      ],
      pointers: ['pickedFrom', 'returnTo', 'user', 'battery', 'invoice'],
      ObjectNames: ['ChargingBox', 'ChargingBox', 'User', 'RentalInvoice'],
      pickedFrom: [
        {
          column: 'deviceName',
          type: 'text'
        },
        {
          column: 'bcode',
          type: 'text'
        },
        {
          column: 'boxCode',
          type: 'text'
        },
        {
          column: 'address',
          type: 'pointer'
        },
        {
          column: 'type',
          type: 'pointer'
        },
        {
          column: 'apk',
          type: 'pointer'
        }
      ],
      returnTo: [
        {
          column: 'deviceName',
          type: 'text'
        },
        {
          column: 'bcode',
          type: 'text'
        },
        {
          column: 'boxCode',
          type: 'text'
        },
        {
          column: 'address',
          type: 'pointer'
        },
        {
          column: 'type',
          type: 'pointer'
        },
        {
          column: 'apk',
          type: 'pointer'
        }
      ],
      user: [
        {
          column: 'username',
          type: 'text'
        },
        {
          column: 'email',
          type: 'text'
        },
        {
          column: 'emailVerified',
          type: 'toggle'
        }
      ],
      battery: [
        {
          column: 'btStat',
          type: 'number'
        },
        {
          column: 'btBay',
          type: 'number'
        },
        {
          column: 'bteq',
          type: 'number'
        },
        {
          column: 'btSn',
          type: 'text'
        }
      ],
      invoice: [
        {
          column: 'discount',
          type: 'number'
        },
        {
          column: 'VAT',
          type: 'number'
        },
        {
          column: 'tax',
          type: 'number'
        },
        {
          column: 'rentAmount',
          type: 'number'
        },
        {
          column: 'status',
          type: 'text'
        },
        {
          column: 'transactionAmount',
          type: 'number'
        },
        {
          column: 'totalAmount',
          type: 'number'
        },
        {
          column: 'netPayableAmount',
          type: 'number'
        },

      ],
    },
    columnLabels: ['select',
      // 'Returned At',
      { label: 'Charging Station Id(picked from)', detailKey: 'pickedFrom', column: 'boxCode' }, // chargingbox pointer
      { label: 'Charging Station Id(return to)', detailKey: 'returnedTo', column: 'boxCode' }, // chargingbox pointer
      // 'User', // user pointer
      // 'Rented At',
      'Battery',  // battery pointer
      'Rented Duration(minutes)',
      'Status',
      'Customer Number',
      'Rental Start Date',
      'Rental Start Time',
      'Rental Return Date',
      'Rental Return Time',
      'Rental Location (venue)',
      'City',
      'Revenue'
    ],
    columnsToDisplay: ['select',
      // 'returnedAt',
      'boxCode', // chargingbox pointer pickedFrom
      'boxCode1', // chargingbox pointer returnedTo
      // 'username', // user pointer
      // 'rentedAt',
      'btSn',  // battery pointer
      'rentedDurationMinutes',
      'status',
      'customerId',
      'RentalStartDate',
      'RentalStartTime',
      'RentalReturnDate',
      'RentalReturnTime',
      'addressLine1', // RentalLocation
      'city',
      'netPayableAmount'// Revenue
    ],
    data: []
  };
  private venueAttributes = {
    btnText: 'Add Venue',
    filters: {
      columns: [
        {
          column: 'locationName',
          type: 'text',
        },
        {
          column: 'zip',
          type: 'text',
        },
        {
          column: 'city',
          type: 'text',
        },
        {
          column: 'state',
          type: 'text',
        },
        {
          column: 'country',
          type: 'text',
        }
      ],
      pointers: [],
      ObjectNames: [],
    },
    columnLabels: ['select',
      'operations',
      'City',
      'State',
      'Zip',
      'Country',
      'Venue Name', // Location Name,
      'Venue Contact Name',
      'Venue Phone',
      'Venue Email',
      'Address',
      'Hours',
      'Category',
      'Charging Station Type',
      'Powerbanks Available',
      'Open Slots Available',
      '# Rental Powerbanks',
      'Last Checkout',
      'Last Return',
      'Is Live?',
      'State Date',
      'Lifetime Rentals',
      'Rentals 7d',
      'Rentals 30d',
      'Rentals YTD',
      'Rentals 24 Hours',
      'Avg Rentals 7d',
      'Avg Rentals 30d',
      'Revenue Lifetime',
      'Revenue 7d',
      'Revene 30d',
      'Opening Timings',
      'Location'
    ],
    columnsToDisplay: ['select',
      'operations',
      'city',
      'state',
      'zip',
      'country',
      'locationName',
      'VenueContactName',
      'VenuePhone',
      'VenueEmail',
      'addressLine1',
      'Hours',
      'Category',
      'ChargingStationType',
      'PowerbanksAvailable',
      'OpenSlotsAvailable',
      '#RentalPowerbanks',
      'LastCheckout',
      'LastReturn',
      'IsLive',
      'StateDate',
      'LifetimeRentals',
      'Rentals7d',
      'Rentals30d',
      'RentalsYTD',
      'Rentals24Hours',
      'AvgRentals7d',
      'AvgRentals30d',
      'RevenueLifetime',
      'Revenue7d',
      'Revene30d',
      'openingTimings',
      'location'
    ],
    data: [
      {
        'venue_name': 'Joes Coffee',
        'contact_name': 'Joe Smith',
        'contact_phone': 2019283736,
        'contact_email': 'joesmith@coffee.com',
        'address': '392 yale avenue',
        'venue_city': 'Los Angeles',
        'venue_category': 'food and beverage',
        'venue_subcategory': 'coffee shop',
        'total_stations': 1,
        'station_type': 'small',
        'is_live': true,
        'start_date': '08-12-2019',
        'rentals_lifetime': 20,
        'rentals_7d': 2,
        'rentals_30d': 12,
        'rentals_ytd': 20,
        'rentals_avg_1d': 2.5,
        'rentals_avg_7d': 4.3,
        'rentals_avg_30d': 12,
        'revenue_lifetime': 100,
        'revenue_7d': 10,
        'revenue_30d': 90,
        'rentals_first_7d': 2,
        'rentals_first_30d': 23,
      },
      {
        'venue_name': 'Toy Shop',
        'contact_name': 'Ronald Smith',
        'contact_phone': 2019283345,
        'contact_email': 'ronaldmith@coffee.com',
        'address': '392 yale avenue',
        'venue_city': 'Los Angeles',
        'venue_category': 'food and beverage',
        'venue_subcategory': 'toy shop',
        'total_stations': 1,
        'station_type': 'small',
        'is_live': true,
        'start_date': '08-12-2019',
        'rentals_lifetime': 20,
        'rentals_7d': 4,
        'rentals_30d': 13,
        'rentals_ytd': 20,
        'rentals_avg_1d': 2.5,
        'rentals_avg_7d': 4.3,
        'rentals_avg_30d': 12,
        'revenue_lifetime': 100,
        'revenue_7d': 10,
        'revenue_30d': 90,
        'rentals_first_7d': 2,
        'rentals_first_30d': 23,
      },
    ]
  };
  private product = {
    columnLabels: ['select',
      'operations',
      'Product Key',
      'Product Name',
      'Product Secret'
    ],
    columnsToDisplay: ['select',
      'operations',
      'productKey',
      'productName',
      'productSecret'
    ],
    data: []
  };
  private chargingBox = {
    btnText: 'Add Charging Box',
    btnScanText: 'Scan Charging Box',
    filters: {
      columns: [
        {
          column: 'deviceName',
          type: 'text'
        },
        {
          column: 'bcode',
          type: 'text'
        },
        {
          column: 'boxCode',
          type: 'text'
        },
        {
          column: 'address',
          type: 'pointer'
        },
        {
          column: 'type',
          type: 'pointer'
        },
        {
          column: 'apk',
          type: 'pointer'
        }
      ],
      pointers: ['address', 'type', 'apk'],
      ObjectNames: ['Address', 'ChargingBoxType', 'Apk'],
      type: [
        {
          column: 'name',
          type: 'text',
        },
        {
          column: 'description',
          type: 'text',
        },
        {
          column: 'totalSlots',
          type: 'number',
        },
      ],
      address: [
        {
          column: 'locationName',
          type: 'text',
        },
        {
          column: 'zip',
          type: 'text',
        },
        {
          column: 'city',
          type: 'text',
        },
        {
          column: 'state',
          type: 'text',
        },
        {
          column: 'country',
          type: 'text',
        }
      ],
      apk: [
        {
          column: 'apkUrl',
          type: 'text',
        },
        {
          column: 'apkMd5',
          type: 'text',
        },
        {
          column: 'apkVersion',
          type: 'text',
        },
        {
          column: 'active',
          type: 'toggle',
        },
      ],
      // chargingBoxStatus:[
      //   {
      //     column: 'status',
      //     type: 'text'
      //   }
      // ]
    },
    columnLabels: ['select',
      'operations',
      'Barcode',
      'Box Code (IMEI NUMBER)',
      // 'Device Secret',
      'Name',
      'Location',
      'Apk Version',
      'Total Slots',
      'Status',
      // 'Product Name',
      { label: 'Product Name', detailKey: 'product', column: 'productName' },
      // 'Product Key',
      // 'Product Secret',
      // 'Region Id'
    ],
    columnsToDisplay: ['select',
      'operations',
      'bcode',
      'boxCode',
      // 'deviceSecret',
      'deviceName',
      'locationName',
      'apkVersion',
      'totalSlots',
      'status',
      'productName',
      // {columnKey: 'productName', pointerIndex: 0}
      // 'productKey',
      // 'productSecret',
      // 'regionId'
    ],
    data: []
  };
  private chargingBoxEarnings = {
    filters: {
      columns: [
        {
          column: 'deviceName',
          type: 'text'
        },
        {
          column: 'bcode',
          type: 'text'
        },
        {
          column: 'boxCode',
          type: 'text'
        },
        {
          column: 'address',
          type: 'pointer'
        },
        {
          column: 'type',
          type: 'pointer'
        },
        {
          column: 'apk',
          type: 'pointer'
        }
      ],
      pointers: ['address', 'type', 'apk'],
      ObjectNames: ['Address', 'ChargingBoxType', 'Apk'],
      type: [
        {
          column: 'name',
          type: 'text',
        },
        {
          column: 'description',
          type: 'text',
        },
        {
          column: 'totalSlots',
          type: 'number',
        },
      ],
      address: [
        {
          column: 'locationName',
          type: 'text',
        },
        {
          column: 'zip',
          type: 'text',
        },
        {
          column: 'city',
          type: 'text',
        },
        {
          column: 'state',
          type: 'text',
        },
        {
          column: 'country',
          type: 'text',
        }
      ],
      apk: [
        {
          column: 'apkUrl',
          type: 'text',
        },
        {
          column: 'apkMd5',
          type: 'text',
        },
        {
          column: 'apkVersion',
          type: 'text',
        },
        {
          column: 'active',
          type: 'toggle',
        },
      ],
      // chargingBoxStatus:[
      //   {
      //     column: 'status',
      //     type: 'text'
      //   }
      // ]
    },
    columnLabels: ['select',
      'operations',
      'Barcode',
      'Box Code (IMEI NUMBER)',
      // 'Device Secret',
      'Name',
      'Location',
      'Apk Version',
      'Total Slots',
      'Earnings'
    ],
    columnsToDisplay: ['select',
      'operations',
      'bcode',
      'boxCode',
      // 'deviceSecret',
      'deviceName',
      'locationName',
      'apkVersion',
      'totalSlots',
      'earnings'
    ],
    data: []
  };
  private ChargingBoxType = {
    btnText: 'Add Charging Box Type',
    // btnScanText: 'Scan Charging Box',
    filters: {
      columns: [
        {
          column: 'name',
          type: 'text',
        },
        {
          column: 'description',
          type: 'text',
        },
        {
          column: 'totalSlots',
          type: 'number',
        },
      ],
      pointers: [],
      ObjectNames: [],
    },
    columnLabels: ['select',
      'operations',
      'Name',
      'Description',
      'Total Slots',
    ],
    columnsToDisplay: ['select',
      'operations',
      'name',
      'description',
      'totalSlots'
    ],
    data: []
  };
  private apk = {
    btnText: 'Add Apk',
    // btnScanText: 'Scan Charging Box',
    filters: {
      columns: [
        {
          column: 'apkUrl',
          type: 'text',
        },
        {
          column: 'apkMd5',
          type: 'text',
        },
        {
          column: 'apkVersion',
          type: 'text',
        },
        {
          column: 'active',
          type: 'toggle',
        },
      ],
      pointers: [],
      ObjectNames: [],
    },
    columnLabels: ['select',
      'operations',
      'Apk Url',
      'Apk Md5',
      'Apk Version',
      // {label: 'Product Name', detailKey: 'product', column: 'productName'},
      { label: 'Type', detailKey: 'type', column: 'name' },
      'Active'
    ],
    columnsToDisplay: ['select',
      'operations',
      'apkUrl',
      'apkMd5',
      'apkVersion',
      'type',
      'active'
    ],
    data: []
  };
  private networkConfig = {
    btnText: 'Add Network',
    // btnScanText: 'Scan Charging Box',
    filters: {
      columns: [
        {
          column: 'wname',
          type: 'text',
        },
        {
          column: 'wpwd',
          type: 'text',
        },
        {
          column: 'connType',
          type: 'text',
        }
      ],
      pointers: [],
      ObjectNames: [],
    },
    columnLabels: ['select',
      'operations',
      'Wifi Name',
      'Wifi Passoword',
      'Connection Type',
    ],
    columnsToDisplay: ['select',
      'operations',
      'wname',
      'wpwd',
      'connType'
    ],
    data: []
  };
  private gc = {
    btnText: 'Add Global Config',
    // btnScanText: 'Scan Charging Box',
    columnLabels: ['select',
      'operations',
      'Name',
      'Time Data',
      'Mill',
      'Apk Url',
      'Apk Md5',
      'Apk Version',
      'Active',
      'wname',
      'wpwd',
      'Connection Type'
    ],
    columnsToDisplay: ['select',
      'operations',
      'name',
      'timeData',
      'mill',
      'apkUrl',
      'apkMd5',
      'apkVersion',
      'active',
      'wname',
      'wpwd',
      'connType'
    ],
    data: []
  };
  private statusOfChargers = {
    filters: {
      columns: [
        {
          column: 'status',
          type: 'text'
        },
        {
          column: 'temper',
          type: 'text'
        },
        {
          column: 'signal',
          type: 'text'
        },
        {
          column: 'connect',
          type: 'text'
        },
        {
          column: 'time',
          type: 'text'
        },
        {
          column: 'currentWifi',
          type: 'text'
        },
        {
          column: 'btOnlinN',
          type: 'text'
        },
        {
          column: 'phone',
          type: 'text'
        },
        {
          column: 'bcode',
          type: 'text'
        },
        {
          column: 'apkV',
          type: 'text'
        },
        {
          column: 'type',
          type: 'text'
        },
        {
          column: 'btAllN',
          type: 'text'
        },
        {
          column: 'chargingBox',
          type: 'pointer'
        },
      ],
      pointers: ['chargingBox'],
      ObjectNames: ['ChargingBox'],
      chargingBox: [
        {
          column: 'deviceName',
          type: 'text'
        },
        {
          column: 'bcode',
          type: 'text'
        },
        {
          column: 'boxCode',
          type: 'text'
        },
        {
          column: 'address',
          type: 'pointer'
        },
        {
          column: 'type',
          type: 'pointer'
        },
        {
          column: 'apk',
          type: 'pointer'
        }
      ]
    },
    columnLabels: ['select',
      // 'operations',
      'Barcode',
      'Temper',
      'signal',
      'connect',
      'currentWifi',
      'btOnlinN',
      'phone',
      'btAllN',
      // 'deviceName' element[this.columnLabels[ind]['detailKey']][this.columnLabels[ind]['column']]
      { label: 'deviceName', detailKey: 'chargingBox', column: 'deviceName' },

    ],
    columnsToDisplay: ['select',
      // 'operations',
      'bcode',
      'temper',
      'signal',
      'connect',
      'currentWifi',
      'btOnlinN',
      'phone',
      'btAllN',
      'deviceName'
    ],
    data: [
      {
        'ChargerId': '1234',
        'StationName': '142, East London',
        'PowerbanksAvailable': 5,
        'SlotsAvailable': 4,
        'PowerbankswithChargeover50Percent': 3,
        'LastCheckout': '13:45PM/03-03-2019',
        'LastReturn': '3:45PM/03-04-2019',
        'StationStatus': 'ONLINE',
        'ChargePercentage': '30%'
      },
      {
        'ChargerId': '1236',
        'StationName': '142, East London',
        'PowerbanksAvailable': 6,
        'SlotsAvailable': 3,
        'PowerbankswithChargeover50Percent': 2,
        'LastCheckout': '13:45PM/03-03-2019',
        'LastReturn': '3:45PM/03-04-2019',
        'StationStatus': 'ONLINE',
        'ChargePercentage': '40%'
      },
      {
        'ChargerId': '1237',
        'StationName': '142, East London',
        'PowerbanksAvailable': 5,
        'SlotsAvailable': 7,
        'PowerbankswithChargeover50Percent': 5,
        'LastCheckout': '13:45PM/03-03-2019',
        'LastReturn': '3:45PM/03-04-2019',
        'StationStatus': 'ONLINE',
        'ChargePercentage': '70%'
      }
    ]
  };
  client;

  constructor() {
    Parse.initialize(environment.PARSE_APP_ID);
    Parse.serverURL = environment.serverURL;
    if (this.client) this.closeLiveQueryClient();
    setTimeout(() => {
      const LiveQueryClient = Parse.LiveQueryClient;
      this.client = new LiveQueryClient({
        applicationId: environment.PARSE_APP_ID,
        serverURL: environment.PARSE_WSS_URL
      });
      this.client.open();
    }, 2000);
  }

  ngOnDestroy() {
    this.closeLiveQueryClient();
  }

  get ParseJs() {
    return Parse;
  }

  get getLiveQueryClient() {
    return this.client;
  }

  closeLiveQueryClient() {
    // return this.client.close();
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  public getRentalAttributes() {
    return this.rentalAttributes;
  }

  public getCustomerAttributes() {
    return this.customerAttributes;
  }

  public getChargingBoxAttributes() {
    return this.chargingBox;
  }
  public getChargingBoxEarningsAttributes() {
    return this.chargingBoxEarnings;
  }

  public getApkAttributes() {
    return this.apk;
  }
  public getNetworkConfigAttributes() {
    return this.networkConfig;
  }

  public getChargingBoxTypeAttributes() {
    return this.ChargingBoxType;
  }

  public getGcAttributes() {
    return this.gc;
  }

  public getVenueAttributes() {
    return this.venueAttributes;
  }

  public getStatusOfChargersAttributes() {
    return this.statusOfChargers;
  }

  public getProduct() {
    return this.product;
  }

  getParse(objectName, pointers, pageNumber?, pageSize?, filters?) {
    // Parse.User.allowCustomUserClass(true);
    const Product = Parse.Object.extend(objectName);
    const query = new Parse.Query(Product);
    if (pageNumber && pageSize) {
      const limit = pageSize * pageNumber;
      const skip = pageSize * (pageNumber - 1);
      if (pageNumber > 1)
        query.skip(skip);
      query.limit(limit);
    }
    if (filters) {
      if (filters.childKey) {
        const child = Parse.Object.extend(filters.childObjectName);
        // var Comment = Parse.Object.extend("Comment");
        let innerQuery = new Parse.Query(child);
        if (filters.operation === 'notEqualTo')
          innerQuery.notEqualTo(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'EqualTo')
          innerQuery.equalTo(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'exists') {
          innerQuery.exists(filters.fieldKey);
          innerQuery.notEqualTo(filters.fieldKey, '');
        }
        if (filters.operation === 'doesNotExist') {
          const tmp1 = new Parse.Query(filters.childObjectName);
          tmp1.doesNotExist(filters.fieldKey);
          const tmp2 = new Parse.Query(filters.childObjectName);
          tmp2.equalTo(filters.fieldKey, '');
          innerQuery = Parse.Query.or(tmp1, tmp2);
        }
        // if (filters.operation === 'doesNotExist')
        //   query.doesNotExist(filters.fieldKey);
        if (filters.operation === 'fullText')
          innerQuery.fullText(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'startsWith')
          innerQuery.startsWith(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'lessThan')
          innerQuery.lessThan(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'greaterThan')
          innerQuery.greaterThan(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'lessThanOrEqualTo')
          innerQuery.lessThanOrEqualTo(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'greaterThanOrEqualTo')
          innerQuery.greaterThanOrEqualTo(filters.fieldKey, filters.fieldValue);

        query.matchesQuery(filters.childKey, innerQuery);
      } else {
        if (filters.operation === 'notEqualTo')
          query.notEqualTo(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'EqualTo')
          query.equalTo(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'exists') {
          query.exists(filters.fieldKey);
          query.notEqualTo(filters.fieldKey, '');
        }
        if (filters.operation === 'doesNotExist') {
          query.doesNotExist(filters.fieldKey);
          // query.equalTo(filters.fieldKey, '');
        }
        // if (filters.operation === 'doesNotExist')
        //   query.doesNotExist(filters.fieldKey);
        if (filters.operation === 'fullText')
          query.fullText(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'startsWith')
          query.startsWith(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'lessThan')
          query.lessThan(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'greaterThan')
          query.greaterThan(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'lessThanOrEqualTo')
          query.lessThanOrEqualTo(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'greaterThanOrEqualTo')
          query.greaterThanOrEqualTo(filters.fieldKey, filters.fieldValue);

      }
    }
    query.withCount();
    query.descending('createdAt');
    pointers.map(pointer => {
      query.include(pointer['relationName']);
    });
    return query.find();
  }

  getParseDataById(objectName, id) {
    const Product = Parse.Object.extend(objectName);
    const query = new Parse.Query(Product);
    return query.get(id);
  }

  getParseFind(objectName, fieldName, fieldValue) {
    const Product = Parse.Object.extend(objectName);
    const query = new Parse.Query(Product);
    query.equalTo(fieldName, fieldValue);
    return query.find();
  }

  getParseData(results, pointers) {
    const arr = [];
    const arrrelatedObject = [];
    let temp: any = {};
    results.map((result, index) => {
      temp = { ...temp, ...this.formatResult(result, 'chargingBoxStatus') };
      arr.push(temp);
      pointers.map(async pointer => {
        if (pointer['relation']) {
          await result.relation(pointer['relationName']).query().each((relatedObject) => {
            console.log(relatedObject);
            arrrelatedObject.push(this.formatResult(relatedObject, 'batteryId'));
          });
          let object = {}
          object[pointer['relationName']] = arrrelatedObject;
          arr[index] = { ...arr[index], ...object };
          this.$pointerData.next(arr);
        }
        if (result.attributes[pointer['relationName']]) {
          let object = {}
          debugger
          object[pointer['relationName']] = this.formatResult(result.attributes[pointer['relationName']], 'chargingBoxId');
          arr[index] = { ...arr[index], ...object };
          arr[index] = { ...arr[index], ...object[pointer['relationName']] };
        }
      });
    });
    return arr;
  }

  formatResult(result, id?) {
    let temp: any = {};
    temp = { ...result.attributes };
    temp[id] = result.id;
    return temp;
  }

  getOptionsData(results, key) {
    debugger
    const arr = [];
    results.map((result, index) => {
      let temp: any = {};
      temp = { ...temp, ...this.formatResult(result, key) };
      temp['object'] = result;
      arr.push(temp);
    });
    return arr;
  }

  postParseData(data, parentForm, objectName, pointers?) {
    const _ObjectParent = Parse.Object.extend(objectName);
    const _objectParent = new _ObjectParent();
    const keys = Object.keys(data);
    parentForm.map(formObj => {
      _objectParent.set(formObj['key'], data[formObj['key']]);
    });
    if (pointers) {
      let childs = [];
      childs = pointers.map(pointer => {
        return this._postParseData(pointer, data);
      });
      Promise.all(childs).then(childSaved => {
        childSaved.map(objchildSaved => {
          if (objchildSaved['className'] === '_User')
            _objectParent.set('user', objchildSaved);
          _objectParent.set(objchildSaved['className'], objchildSaved);
        });
        // _objectParent.set(pointer['objectName'], childSaved);
        _objectParent.save().then(prnt => {
          return true;
        }, (error) => {
          return false;
        });
      });

    }
  }

  _postParseData(pointer, data) {
    if (pointer['objectName'] === 'User') {
      const user = new Parse.User();
      pointer['formData'].map(formObj => {
        user.set(formObj['key'], data[formObj['key']]);
      });
      return user.signUp();
    } else {
      const _Object = Parse.Object.extend(pointer['objectName']);
      const _object = new _Object();
      pointer['formData'].map(formObj => {
        _object.set(formObj['key'], data[formObj['key']]);
      });
      return _object.save();
    }
  }

  cloudFunction(funcName, params) {
    return Parse.Cloud.run(funcName, params);
  }

  showNotification(type, message) {
    // const type = ['','info','success','warning','danger'];

    // const color = Math.floor((Math.random() * 4) + 1);
    const from = 'top';
    const align = 'right';
    $.notify({
      icon: "notifications",
      message: message

    }, {
      type: type,
      timer: 3000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon container-notif" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

}
